import React, { useEffect } from 'react';
import 'jquery-sticky';

const Sticky = ({$,browserWindow}) => {

    useEffect(() => {
        // Inicialize o plugin sticky aqui ou adicione qualquer lógica necessária
        if ($.fn.sticky) {
            $(".palatin-main-menu").sticky({
                topSpacing: 0
            });
        }

    }, [$]);

    return (
      <></>
    );
};

export default Sticky;
