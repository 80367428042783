import React, {useEffect} from 'react';
import '../../Assets/LegacyPlugins/scrollUp'; // Importe o arquivo JavaScript encapsulado

const ScrollUp = ({$, browserWindow}) => {

    useEffect(() => {
        // Inicialize o plugin scrollUp aqui ou adicione qualquer lógica necessária
        // por exemplo, chamar l.fn.scrollUp.init() com as configurações desejadas
        // console.log(jQuery)
        if ($.fn.scrollUp) {

            browserWindow.scrollUp({
                scrollSpeed: 1000,
                scrollText: '<i class="fa fa-angle-up"></i>'
            });
        }

    }, [$, browserWindow]);

    return (<></>);
};

export default ScrollUp;
