import React, {useEffect, useState} from 'react';
import {IMenu} from "../../Interfaces/IMenu";
import {IMenuItem} from "../../Interfaces/IMenuItem";
import {filterMenuItemsByObjectType} from "../../Services/WordpressApi";
import {Route, Routes} from "react-router-dom";
import PageComponent from "./PageComponent/PageComponent";
import Preloader from "./Preloader/Preloader";

interface NavigationPagesProps {
  menuStart: IMenu,
  dontLoadPages?: string[];
}

/**
 * Captura itens do menu principal
 * Popula o Router Dom com slug das pages por menu
 * @param dontLoadPages
 * @constructor
 */
const NavigationPages: React.FC<NavigationPagesProps> = ({dontLoadPages, menuStart}) => {
  
  const [menu, setMenu] = useState<IMenu | null>(menuStart);
  const [menuItemsPages, setMenuItemsPages] = useState<IMenuItem[] | null>(null);
  
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        
        if (menu) {
          const filteredMenuItems = await filterMenuItemsByObjectType(menu.items);
          setMenuItemsPages(filteredMenuItems)
        }
        
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchMenu();
    
  }, [menu]);
  
  /*Loading*/
  if (!menu  || !menuItemsPages) return <Preloader/>
  
  return (
    
    <>
      {/*Carregamento de rotas por slug da page(wordpres api)*/}
      <Routes>
        
        {/*Carregamento de rotas e apontameno de Componentes Page*/}
        {menuItemsPages && menuItemsPages.map(function (menuItem: IMenuItem) {
          
          if (dontLoadPages && dontLoadPages.indexOf(menuItem.object_slug) > -1) {
            return false;
          } else {
            
            const parentPage = menuItemsPages.find( (p) => menuItem.parent === p.id );
            
            return <Route
              key={menuItem.id}
              path={`/${menuItem.object_slug}`}
              element={<PageComponent menu={menu} slug={menuItem.object_slug} items={menuItem.children} parentPage={parentPage}/>}
            />
            
          }
          
        })}
      
      </Routes>
    
    </>
  );
};

export default NavigationPages;