import React from "react";
import {Link} from "react-router-dom";
import logoUerj from "../../Assets/Images/logo-uerj.png";

export const LogoUerj: React.FC<any> = () => {
  
  return (
    <>
      {/*Logo*/}
      {/*<a href="index.html" className="nav-brand"></a>*/}
      <div style={{"cursor": "pointer"}} className="bloco-uerj">
        
        <Link to="#" className="nav-brand"/>
        <Link to="/" className="navbar-brand conjunto">
          
          <div className="logo-pr2">
            <img className="img-fluid d-block " src={logoUerj}
                 alt="novo"/>
          </div>
        
        </Link>
      
      </div>
    </>
  );
}