import axios from 'axios';

export class YoutubeService {
  private static readonly BASE_URL = 'https://www.googleapis.com/youtube/v3';
  
  public static async fetchYoutubeVideos(apiKey: string, channelId: string) {
    const url = `${YoutubeService.BASE_URL}/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=20`;
    const response = await axios.get(url);
    return response.data.items;
  }
}