import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import HomeScripts from '../../Home/HomeScripts';
import {Page} from '../../../../Interfaces/IPage';
import {WPGalleryAlbums} from "../../../../Services/WordpressApi";
import imageIpeUerj from '../../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';
import Preloader from "../../../Sections/Preloader/Preloader";
import {IMenu} from "../../../../Interfaces/IMenu";
import Header from "../../../Sections/Header/Header";
import FooterArea from "../../../Sections/Footer/FooterArea";
import './GalleryVideos.scss';
import {YoutubeService} from "../../../../Services/YoutubeService";
import VideoModal from "../../../Sections/VideoModal/VideoModal";

interface GalleryVideosComponentProps {
  menu: IMenu,
  slug?: string;
}

const GalleryVideos: React.FC<GalleryVideosComponentProps> = ({menu}) => {
  
  const [videos, setVideos] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [modalVideoId, setModalVideoId] = useState<string | null>(null);
  
  const openModal = (videoId: string) => {
    setModalVideoId(videoId);
  };
  
  const closeModal = () => {
    setModalVideoId(null);
  };
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        const apiKey = 'AIzaSyBbxkhIRr3QBB5H9fMsBzSuwY03oGcv7t8';
        const channelId = 'UCfX4QVnzQscoJv1IQhWlNCw';
        YoutubeService.fetchYoutubeVideos(apiKey, channelId)
          .then(videos => setVideos(videos || null))
          .catch(error => setError(error));
        
        
        /* Pegar o slug do pagina aqui*/
        
        if (videos) {
          console.log(videos);
        } else {
          setVideos(null);
        }
        
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, []);
  
  if (loading) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (<>
    
    {modalVideoId && (
      <VideoModal
        videoId={modalVideoId}
        isOpen={!!modalVideoId}
        onRequestClose={closeModal}
      />
    )}
    
    <Header menu={menu}/>
    
    <div className="allnews">
      
      {/*##### Breadcumb Area Start #####*/}
      {videos ? (<>
          {/*<SidebarMenu menuItems={menuItems} />*/}
          <section className="breadcumb-area d-flex align-items-center justify-content-center">
            
            <div className="breadcumb-area bg-img header-image-background"
                 style={{
                   "position": "absolute", "backgroundImage": `url(${imageIpeUerj})`, "zIndex": -1
                 }}>
            </div>
            
            <div className="bradcumbContent">
              <h2>Galeria de Videos</h2>
              {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
            </div>
          
          </section>
          
          {/*  <!-- ##### Blog Area Start ##### */}
          <div className="blog-area section-padding-0-100">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="palatin-blog-posts">
                    {/*VIDEOS*/}
                    <div className="container">
                      <div className="row">
                        {videos.map((video, index) => (
                          
                          
                          <div className="col-md-3" key={index}>
                            <div className="single-blog-post style-3 wow fadeInUp" data-wow-delay="300ms">
                              <div className="post-thumb">
                                
                                <div className="post-thumb">
                                  <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank">
                                    <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title}/>
                                  </a>
                                  <div className="overlay">
                                    <div className="play-icon" onClick={() => openModal(video.id.videoId)}></div>
                                  </div>
                                </div>
                                
                                {/*<a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank">*/}
                                {/*  <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title}/>*/}
                                {/*</a>*/}
                              </div>
                              <div className="post-data">
                                <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank"
                                   className="post-title">
                                  <h6>{video.snippet.title}</h6>
                                </a>
                                <div className="post-meta">
                                  <p className="post-author">Postado por <a href="#">{video.snippet.channelTitle}</a>
                                  </p>
                                  <p
                                    className="post-date">{new Date(video.snippet.publishedAt).toLocaleDateString()}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  
                  
                  </div>
                </div>
                
                {/*<div className="col-12">*/}
                {/*  <div className="load-more-btn text-center wow fadeInUp" data-wow-delay="700ms">*/}
                {/*    <a href="#" className="btn palatin-btn">Carregar mais</a>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          
          <div style={{"marginBottom": "60px"}}></div>
        
        </>
      
      ) : (<div>Page not found</div>)}
    
    </div>
    
    <FooterArea/>
    
    {/*<SCRIPTS>*/}
    <HomeScripts/>
    {/*</SCRIPTS>*/}
  
  </>);
};

export default GalleryVideos;
