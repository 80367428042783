import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import Header from '../../Sections/Header/Header';
import FooterArea from '../../Sections/Footer/FooterArea';
import HomeScripts from '../Home/HomeScripts';
import {Page} from '../../../Interfaces/IPage';
import {fetchPageBySlug} from "../../../Services/WordpressApi";
import imageIpeUerj from '../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';
import photo800x1000 from '../../../Assets/Images/bg-img/800x1000-ph.jpg';
import photoDiretor from '../../../Assets/Images/funcionarios/brant1.jpg';
import photoSuzana from '../../../Assets/Images/funcionarios/suzana1.jpg';
import photoEmilio from '../../../Assets/Images/funcionarios/emilio1.jpg';
import photoFernando from '../../../Assets/Images/funcionarios/fernando1.jpg';
import photoRoberto from '../../../Assets/Images/funcionarios/roberto1.jpg';
import photoSheila from '../../../Assets/Images/funcionarios/sheila1.jpg';
import photoTelma from '../../../Assets/Images/funcionarios/telma1.jpg';
import photoThay from '../../../Assets/Images/funcionarios/thay1.jpg';
import photoThiago from '../../../Assets/Images/funcionarios/thiago1.jpg';
import photoMateus from '../../../Assets/Images/funcionarios/mateus1.jpg';
import photoRegina from '../../../Assets/Images/funcionarios/regina1.jpg';

import './AboutUsPage.scss';
import Preloader from "../../Sections/Preloader/Preloader";
import {IMenu} from "../../../Interfaces/IMenu";


interface AboutUsPageProps {
  // slug: string;
  menu: IMenu,
}

const AboutUsPage: React.FC<AboutUsPageProps> = ({menu}) => {
  
  const [page, setPage] = useState<Page | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        
        const slug: string = 'quem-somos-inst';
        
        const page: Page | null = await fetchPageBySlug(slug);
        
        if (page) {
          setPage(page);
        } else {
          setPage(null);
        }
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
  }, []);
  
  if (loading) {
    return <Preloader/>;
  }
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (<>
    
    <Header menu={menu}/>
    
    <div>
      {/*##### Breadcumb Area Start #####*/}
      {page ? (<>
          <section className="breadcumb-area d-flex align-items-center justify-content-center">
            
            <div className="breadcumb-area bg-img header-image-background"
                 style={{
                   "position": "absolute", "backgroundImage": `url(${imageIpeUerj})`, "zIndex": -1
                 }}>
            </div>
            
            <div className="bradcumbContent">
              <h2>{page.title.rendered}</h2>
              {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
            </div>
          
          </section>
          
          <section className="about-us-area" style={{"marginTop": "4rem"}}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-12">
                  <div className="about-text mb-100">
                    <div className="section-heading">
                      <div className="line-"></div>
                      <h2>O InovUERJ</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: page.content.rendered}}/>
                    {/*<a href="#" className="btn palatin-btn mt-50">Read More</a>*/}
                  </div>
                </div>
                
                {/*<div className="col-12 col-lg-6">*/}
                {/*  <div className="about-thumbnail mb-100">*/}
                {/*    <img src={aboutImageInovuerjLight} alt=""/>*/}
                {/*  </div>*/}
                {/*</div>*/}
              
              </div>
            </div>
          </section>
          
          <section className="rooms-area section-padding-0-100">
            
            <div className="container">
              
              <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                  <div className="section-heading text-center">
                    <div className="line-"></div>
                    <h2>Equipe</h2>
                    <p>Nosso Quadro de Funcionários</p>
                  </div>
                </div>
              </div>
              
              <div className="row">
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoDiretor})`}}></div>
                    <p className="price-from">Prof. José Brant</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Direção</h4>
                      <p>Gestor do Departamento do Inovação. </p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
              
              </div>
              
              {/* Coordenadores  */}
              <div className="row">
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp"  data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoTelma})`}}></div>
                    <p className="price-from">Telma Costa</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Coordenação do Escritório de Propriedade Intelectual</h4>
                      <p>Coordenação do Escritório de Propriedade Intelectual.</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoSuzana})`}}></div>
                    <p className="price-from">Suzana Bottega Peripolli</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Coordenação de Escritório de Projetos</h4>
                      <p>Coordenação de Escritório de Projetos.</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoSheila})`}}></div>
                    <p className="price-from">Sheila Perlingeiro</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Coordenação Observatório de Inovação</h4>
                      <p>Coordenação de plataformas digitais e sistemas do Departamento do Inovação</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
              
              
              </div>
              
              {/* Gerentes  */}
              <div className="row">
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoRegina})`}}></div>
                    <p className="price-from" style={{"minWidth": "279px"}}>Regina Helena Tunes(Licença Procad).</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Coordenação do Escritório de Propriedade Intelectual.</h4>
                      <p>Coordenação do Escritório de Propriedade Intelectual.</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoFernando})`}}></div>
                    <p className="price-from">Fernando Gagno</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Gerente de Projetos</h4>
                      <p>Coordenação de cursos e eventos relacionados a Casa de Software do Departamento do
                        Inovação.</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    {/*<div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoDiretora})`}}></div>*/}
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoThay})`}}></div>
                    <p className="price-from">Thayaná Cunha</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Secretaria Executiva</h4>
                      <p>Chefe de Secretaria</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                
              
              </div>
              
              <div className="row">
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoThiago})`}}></div>
                    <p className="price-from">Thiago Rodrigues</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Propriedade Intelectual</h4>
                      <p>Propriedade Intelectual</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoRoberto})`}}></div>
                    <p className="price-from">Roberto Hiran</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Gerente de Projetos</h4>
                      <p>Gerente de Projetos</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    {/*<div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoDiretora})`}}></div>*/}
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoEmilio})`}}></div>
                    <p className="price-from">Emílio Jorge</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Desenvolvedor de Software</h4>
                      <p>Desenvolvimento de plataformas e sistemas web</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
                    <div className="bg-thumbnail bg-img" style={{backgroundImage: `url(${photoMateus})`}}></div>
                    <p className="price-from">Mateus Moraes</p>
                    <div className="rooms-text">
                      <div className="line"></div>
                      <h4>Secretaria Executiva
                      </h4>
                      <p>Apoio Administrativo</p>
                    </div>
                    <a href="#" className="book-room-btn btn palatin-btn">Informações</a>
                  </div>
                </div>
              
              </div>
            
            </div>
          
          </section>
        
        </>
      
      ) : (<div>Page not found</div>)}
      
      {/*##### Breadcumb Area End #####*/}
    
    </div>
    
    <FooterArea/>
    
    {/*<SCRIPTS>*/}
    <HomeScripts/>
    {/*</SCRIPTS>*/}
  
  </>);
};

export default AboutUsPage;
