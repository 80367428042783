import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import HomeScripts from '../../Home/HomeScripts';
import {Page} from '../../../../Interfaces/IPage';
import {WPAllNews} from "../../../../Services/WordpressApi";
import imageIpeUerj from '../../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';

import './AllNewsComponent.scss';
import Preloader from "../../../Sections/Preloader/Preloader";
import {IMenu} from "../../../../Interfaces/IMenu";
import Header from "../../../Sections/Header/Header";
import FooterArea from "../../../Sections/Footer/FooterArea";
import {ativar} from "../../../Plugins/ClassyNav";


interface AllNewsComponentProps {
  menu: IMenu,
  slug?: string;
}

const AllNewsComponent: React.FC<AllNewsComponentProps> = ({menu}) => {
  
  const [pages, setPages] = useState<Page[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        /* Pegar o slug do pagina aqui*/
        
        const pagesApi: Page[] | null = await WPAllNews(); // pega a page na api do wordpress
        
        if (pagesApi) {
          setPages(pagesApi);
        } else {
          setPages(null);
        }
        
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, []);
  
  if (loading) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (
    <>
      
      <Header menu={menu}/>
      
      <div className="allnews">
        {/*##### Breadcumb Area Start #####*/}
        {
          pages ?
            (
              <>
                {/*<SidebarMenu menuItems={menuItems} />*/}
                <section className="breadcumb-area d-flex align-items-center justify-content-center">
                  
                  <div className="breadcumb-area bg-img header-image-background"
                       style={{
                         "position": "absolute",
                         "backgroundImage": `url(${imageIpeUerj})`,
                         "zIndex": -1
                       }}>
                  </div>
                  
                  <div className="bradcumbContent">
                    <h2>Notícias</h2>
                    {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
                  </div>
                
                </section>
                
                <div className="book-now-area">
                </div>
                
                
                {/*  <!-- ##### Blog Area Start ##### */}
                <div className="blog-area section-padding-0-100">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="palatin-blog-posts">
                          
                          {pages.map((item) => (
                            
                            <div className="single-blog-post mb-100 wow fadeInUp " data-wow-delay="100ms">
                              
                              {
                                item.slug !== 'prezados-nossos-telefones-estao-com-problemas-estamos-atendendo-por-email-do-inovuerjsr2-uerj-br' && (
                                  
                                  <div>
                                    
                                    <div className="blog-post-thumb justify-content-center text-center">
                                      {/*<img className="img-responsive" src={item.imagem_capa} alt=""/>*/}
                                    </div>
                                    
                                    <div className="post-content">
                                      
                                      
                                      <a href="#" className="post-date btn palatin-btn">{item.date_formatted}</a>
                                      <a href="#" className="post-title">{item.title.rendered}</a>
                                      <div className="post-meta d-flex justify-content-center">
                                        <a href="#" className="post-catagory">Categoria: Últimas Notícias</a>
                                        <a href="#" className="post-comments">0 comentários</a>
                                      </div>
                                      <p dangerouslySetInnerHTML={{__html: item.content.rendered || ""}}/>
                                    
                                    </div>
                                  
                                  
                                  </div>
                                
                                )
                                
                              }
                            
                            
                            </div>
                          
                          ))}
                        
                        
                        </div>
                      </div>
                      
                      <div className="col-12">
                        <div className="load-more-btn text-center wow fadeInUp" data-wow-delay="700ms">
                          <a href="#" className="btn palatin-btn">Carregar mais</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  ##### Blog Area End #####  */}
                
                
                <div style={{"marginBottom": "60px"}}></div>
              
              </>
            
            ) : (
              <div>Page not found</div>
            )
        }
        
        {/*##### Breadcumb Area End #####*/}
      
      </div>
      
      <FooterArea/>
      
      {/*<SCRIPTS>*/}
      <HomeScripts/>
      {/*</SCRIPTS>*/}
    
    </>
  );
};

export default AllNewsComponent;
