import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import HomeScripts from '../../Home/HomeScripts';
import {Page} from '../../../../Interfaces/IPage';
import {WPGalleryAlbums} from "../../../../Services/WordpressApi";
import imageIpeUerj from '../../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';
import Preloader from "../../../Sections/Preloader/Preloader";
import {IMenu} from "../../../../Interfaces/IMenu";
import Header from "../../../Sections/Header/Header";
import FooterArea from "../../../Sections/Footer/FooterArea";
import {Carousel} from 'react-bootstrap';
import './GalleryImages.scss';

interface GalleryImagesComponentProps {
  menu: IMenu,
  slug?: string;
}

const GalleryImages: React.FC<GalleryImagesComponentProps> = ({menu}) => {
  
  const [albums, setAlbuns] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        /* Pegar o slug do pagina aqui*/
        
        const albunsApi: Page[] | null = await WPGalleryAlbums(); // pega a page na api do wordpress
        
        if (albunsApi) {
          setAlbuns(albunsApi);
        } else {
          setAlbuns(null);
        }
        
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, []);
  
  if (loading) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (<>
      
      <Header menu={menu}/>
      
      <div className="allnews">
        
        {/*##### Breadcumb Area Start #####*/}
        {albums ? (<>
            {/*<SidebarMenu menuItems={menuItems} />*/}
            <section className="breadcumb-area d-flex align-items-center justify-content-center">
              
              <div className="breadcumb-area bg-img header-image-background"
                   style={{
                     "position": "absolute", "backgroundImage": `url(${imageIpeUerj})`, "zIndex": -1
                   }}>
              </div>
              
              <div className="bradcumbContent">
                <h2>Galeria de Imagens</h2>
                {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
              </div>
            
            </section>
            
            <div className="book-now-area">
            </div>
            
            
            {/*  <!-- ##### Blog Area Start ##### */}
            <div className="blog-area section-padding-0-100">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="palatin-blog-posts">
                      
                      {albums.map((item) => (
                        
                        <div className="single-blog-post mb-100 wow fadeInUp " data-wow-delay="100ms">
                          
                          <div className="blog-post-thumb justify-content-center text-center">
                            {/*<img className="img-responsive" src={item.photos[0]} alt=""/>*/}
                          </div>
                          
                          <div className="post-content">
                            
                            <a href="#" className="post-date btn palatin-btn">{item.title}</a>
                            <a href="#" className="post-title">{item.title}</a>
                            <div className="post-meta d-flex justify-content-center">
                              {/*<a href="#" className="post-catagory">Categoria: Últimas Notícias</a>*/}
                              {/*<a href="#" className="post-comments">0 comentários</a>*/}
                            </div>
                            <p dangerouslySetInnerHTML={{__html: item.title || ""}}/>
                            
                            <Carousel>
                              {item.photos && item.photos.map((p: any, k: number) => (<Carousel.Item key={k}>
                                  <img
                                    className="d-block w-100"
                                    src={p} // Use a primeira foto do álbum
                                    alt=""
                                  />
                                  <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                  </Carousel.Caption>
                                </Carousel.Item>))}
                            </Carousel>
                          
                          </div>
                        
                        </div>
                      
                      ))}
                    
                    </div>
                  </div>
                  
                  <div className="col-12">
                    <div className="load-more-btn text-center wow fadeInUp" data-wow-delay="700ms">
                      <a href="#" className="btn palatin-btn">Carregar mais</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  ##### Blog Area End #####  */}
            
            
            <div style={{"marginBottom": "60px"}}></div>
          
          </>
        
        ) : (<div>Page not found</div>)}
        
        {/*##### Breadcumb Area End #####*/}
      
      </div>
      
      <FooterArea/>
      
      {/*<SCRIPTS>*/}
      <HomeScripts/>
      {/*</SCRIPTS>*/}
    
    </>);
};

export default GalleryImages;
