import React, {useEffect} from 'react';
import "nicescroll";

const NiceScroll = ({$}) => {

    useEffect(() => {
        // Inicialize o plugin toolTip aqui ou adicione qualquer lógica necessária
        if ($.fn.niceScroll) {
            $(".album-all-songs").niceScroll({
                background: "#fff"
            });
        }
    }, [$]);

    return (
        <>
        </>
    );
};

export default NiceScroll;
