import React, {useEffect} from 'react';

/*Core Jquery*/
import 'jquery';
import 'popper.js';
import 'bootstrap';

/*Plugins*/
import ClassyNav from "../../Plugins/ClassyNav";
import NiceSelect from "../../Plugins/NiceSelect";
import OwlCarousel from "../../Plugins/OwlCarousel";
import ScrollUp from "../../Plugins/ScrollUp";
import CounterUp from "../../Plugins/CounterUp";
import Sticky from "../../Plugins/Sticky";
import CircleProgress from "../../Plugins/Circle";
import ToolTip from "../../Plugins/ToolTip";
import NiceScroll from "../../Plugins/NiceScroll";
import AnchorPreventClick from "../../Plugins/PreventClick";
import PluginWowJs from "../../Plugins/Wow";

/*Hack to unsed warnings*/
import $ from 'jquery';

/*object window contextualizado pelo jquery e injetado globalmente*/
const browserWindow = $(window);
window.$ = window.jQuery = $;

/**
 * Compomente Homepage
 * Página inicial do Portal
 */
const HomeScripts = () => {

    useEffect(() => {

        // // :: 1.0 Preloader Active Code
        // browserWindow.on('load', function () {
        //     $('.preloader').fadeOut('slow', function () {
        //         $(this).remove();
        //     });
        // });

    }, []);

    return (
        <>

            {/* :: 2.0 Nav Active Code: Importa script do ClassyNav */}
            <ClassyNav $={$} options={{}}/>

            {/* :: 3.0 Nice-select Active Code: Estiliza todas as combo selects *!/*/}
            <NiceSelect $={$}/>

            {/* :: 4.0 Sliders Active Code *!/*/}
            {/*<OwlCarousel $={$}/>*/}

            {/* :: 5.0 ScrollUp Active Code*/}
            <ScrollUp $={$} browserWindow={browserWindow}/>

            <CounterUp $={$}/>

            {/* :: 7.0 Sticky Active Code*/}
            <Sticky $={$}/>

            <CircleProgress $={$}/>

            <ToolTip $={$}/>

            {/*// :: 10.0 niceScroll Active Code*/}
            <NiceScroll $={$}/>

            <AnchorPreventClick $={$}/>

            {/*// :: 12.0 wow Active Code: Efeito de loading ao descer a página (largura acima de 767)*/}
            {browserWindow.width() > 767 && <PluginWowJs/>}

        </>
    );
};

export default HomeScripts;
