import React, {useEffect, useState} from 'react';
import {IMenuItem} from "../../Interfaces/IMenuItem";
import Preloader from "./Preloader/Preloader";
import {Link} from "react-router-dom";
import {WPlinkDetails} from "../../Services/WordpressApi";

interface SecundaryNavProps {
  items: IMenuItem[]
}

/**
 * Captura itens do menu principal
 * Popula o Router Dom com slug das pages por menu
 * @param dontLoadPages
 * @constructor
 */
const SecundaryNav: React.FC<SecundaryNavProps> = ({items}) => {
  
  const [menu, setMenu] = useState<IMenuItem[] | null>(items);
  
  useEffect(() => {
    console.log(menu);
  }, []);
  
  if (menu == null) {
    return <Preloader/>
  }
  
  return (
    
    <>
      
      {/*Carregamento de rotas e apontameno de Componentes Page*/}
      {menu && menu.map((menuItem: IMenuItem, key: number) => {
          
          const {to, target} = WPlinkDetails(menuItem);
          
          return (
            
            <Link to={to} className="form-group menu-link" target={target} key={key}>
              <div key={menuItem.id}>
                
                <label htmlFor="select1">
                  {menuItem.title}
                </label>
              
              </div>
            </Link>
          
          )
          
        }
      )}
    
    </>
  );
};

export default SecundaryNav;