import React, {useEffect, useState} from 'react';
import {IMenu} from "../../Interfaces/IMenu";
import {IMenuItem} from "../../Interfaces/IMenuItem";
import {Link} from "react-router-dom";
import Preloader from "./Preloader/Preloader";
import {WPlinkDetails} from "../../Services/WordpressApi";
import "./HeaderNav.scss"

interface HeaderNavProps {
  menuStart: IMenu
}

/**
 * Captura itens do menu principal
 * Popula o Router Dom com slug das pages por menu
 * @param dontLoadPages
 * @constructor
 */
const HeaderNav: React.FC<HeaderNavProps> = ({menuStart}) => {

  const [menu, setMenu] = useState<IMenu | null>(menuStart);

  useEffect(() => {
  }, []);

  if (menu == null) {
    return <Preloader/>
  }

  return (

    <>
      {/* Nav Start - Render Navbar do Header com Links */}
      <div className="classynav">

        <ul>

          {/*<li className="active"><Link to="/">Home</Link></li>*/}

          {/*Carregamento de rotas e apontameno de Componentes Page*/}
          {menu && menu.items.map((menuItem: IMenuItem, key) =>

            <li key={menuItem.id} style={{"zIndex": `${menu.items.length - key}`}}>

              {menuItem.object == 'page' ? <Link to={menuItem.object_slug}>{menuItem.title}</Link> :
                <a href={menuItem.url} target="_blank">{menuItem.title}</a>}

              {menuItem.children && (
                <ul className="dropdown">
                  {menuItem.children.map((item) => {

                    const {to, target} = WPlinkDetails(item);

                    return (
                      <li key={item.id} style={{"zIndex": `${key}`}}>
                        {/*<a href="index.html">QUEM SOMOS</a>*/}
                        <Link to={to} target={target}>{item.title}</Link>
                      </li>
                    );

                  })}
                </ul>
              )}

            </li>
          )}

        </ul>

      </div>
    </>
  );
};

export default HeaderNav;