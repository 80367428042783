import React from 'react';
import Modal from 'react-modal';
import './VideoModal.scss';

interface VideoModalProps {
  videoId: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoId, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Video Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
          color: 'lightsteelblue'
        }
      }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button className="btn-close" onClick={onRequestClose}></button>
    </Modal>
  );
};

export default VideoModal;