import React, {useEffect} from "react";

interface RedirectComponentProps {
  to: string;
}
export const RedirectTo: React.FC<RedirectComponentProps> = ({to}) => {
  useEffect(() => {
    window.location.href = to;
  }, []);
  return <></>;
}