import React, {useEffect} from 'react';
import {IMenuItem} from "../../Interfaces/IMenuItem";
import "./PageChildren.scss";
import SecundaryNav from "./SecundaryNav";


interface PageChildrenProps {
  items: IMenuItem[]
}

const PageChildren: React.FC<PageChildrenProps> = ({items}) => {
  
  useEffect(() => {
  }, []);
  
  return (
    <div className="book-now-area">
      {/*##### Book Now Area Start ##### */}
      <div className="container">
        <div className="row justify-content-center">
          
          <div className="col-12 col-lg-10">
            <div className="book-now-form" style={{"top": "0px"}}>
              <form id="form-icones">
                
                <SecundaryNav items={items}/>
              
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*##### Book Now Area End ##### */}
    </div>
  );
}

export default PageChildren;