import axios, {AxiosError} from 'axios';
import {Page} from "../Interfaces/IPage";
import {IMenu} from "../Interfaces/IMenu";
import {IMenuItem} from "../Interfaces/IMenuItem";
import {IUerjNumbers} from "../Interfaces/IUerjNumbers";

// const domain: string = "http://152.92.181.76:8000";
const domain: string = "http://api.inovuerj.pr2.uerj.br"; // server novo serad
const http_api_v2: string = `${domain}/wp-json/wp/v2`;
const wpApiMenusEndpoint: string = `${domain}/wp-json/wp-api-menus/v2/menus`;
const wpApiNewsEndpoint: string = `${domain}/wp-json/news/v1`;
const wpApiAlbumsEndpoint: string = `${domain}/wp-json/custom/v1`;
const wpApiUerjNumbersEndpoint: string = `${domain}/wp-json/uerj-em-numeros/v1`;

/**
 * Recuperar uma Page do Wordpress através do apelido(slug)
 * @param slug
 */
export const fetchPageBySlug = async (slug: string): Promise<Page | null> => {
  try {
    const response = await axios.get<Page[]>(`${http_api_v2}/pages?slug=${slug}`);
    if (response.data.length > 0) {
      const result = response.data.filter((item) => item.status === "publish"); // nao pegar rascunho
      return result[0];
    } else {
      return null;
    }
  } catch (error) {
    throw error as AxiosError;
  }
};

/**
 * Recuperar um Menu Wordpress API
 * @param menuId
 */
export const fetchMenuItemsById = async (menuId: number): Promise<IMenu | null> => {
  try {
    const endpoint = `${wpApiMenusEndpoint}/${menuId}`;
    const response = await axios.get<IMenu>(endpoint);
    const menu: IMenu = response.data;

    return menu || null;
  } catch (error) {
    throw error;
  }
};

/**
 * Recursividade para filtrar todas as "pages"
 * @param menuItems
 */
export const filterMenuItemsByObjectType = (menuItems: IMenuItem[]): IMenuItem[] => {
  const filteredItems: IMenuItem[] = [];
  for (const item of menuItems) {
    if (item.object === 'page') {
      filteredItems.push(item);
    }
    if (item.children) {
      const filteredChildren = filterMenuItemsByObjectType(item.children);
      filteredItems.push(...filteredChildren);
    }
  }
  return filteredItems;
};

/**
 * Recuperar últimas noticias do wordpress.
 */
export const WPNewsList = async (): Promise<any | null> => {
  try {
    const endpoint = `${wpApiNewsEndpoint}/list`;
    const response = await axios.get<any>(endpoint);

    console.log(response);

    const resp: any = response.data;

    return resp || null;
  } catch (error) {
    throw error;
  }
};


export const WPlinkDetails = (menuItem: IMenuItem) => {


  const link: string = isLink(menuItem) ? menuItem.url : '';
  const page: string = isPage(menuItem) ? menuItem.object_slug : '';

  const to: string = isLink(menuItem) ? link : `/${page}`;
  const target: string = isLink(menuItem) ? '_blank' : '_self';

  return {to, target};

}

export const isLink = (menuItem: IMenuItem): boolean => {
  return menuItem.object === 'custom';
}

export const isPage = (menuItem: IMenuItem): boolean => {
  return menuItem.object === 'page';
}


/**
 * Recuperar noticia atraves do name(slug) do wordpress.
 */
export const WPNewsBySlug = async (slug: string): Promise<any | null> => {
  try {
    const endpoint = `${wpApiNewsEndpoint}/view/${slug}`;
    const response = await axios.get<any>(endpoint);
    const resp: any = response.data;


    if (resp && resp.length == 0) {
      return null;
    }

    return resp;

  } catch (error) {
    throw error;
  }
};


/**
 * Recuperar TODAS noticias do wordpress.
 */
export const WPAllNews = async (limit: number = 10, offset: number = 1): Promise<Page[] | null> => {
  try {
    const endpoint = `${wpApiNewsEndpoint}/all`;
    const response = await axios.get<any>(endpoint);
    const resp: any = response.data;

    return resp || null;
  } catch (error) {
    throw error;
  }
};


export const WPUerjNumbers = async (limit: number = 10, offset: number = 1): Promise<IUerjNumbers[] | null> => {
  try {
    const endpoint = `${wpApiUerjNumbersEndpoint}/list`;
    const response = await axios.get<any>(endpoint);
    const resp: any = response.data;
    return resp || null;
  } catch (error) {
    throw error;
  }
};



/**
 * Recuperar todos os albuns do envira-galery Wordpress
 */
export const WPGalleryAlbums = async (limit: number = 10, offset: number = 1): Promise<any[] | null> => {
  try {
    const endpoint = `${wpApiAlbumsEndpoint}/albums`;
    const response = await axios.get<any>(endpoint);
    const resp: any = response.data;

    console.log(resp);

    return resp || null;
  } catch (error) {
    throw error;
  }
};





