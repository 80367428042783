import React, {useState} from 'react';
import jQuery from "jquery";

const $ = jQuery;
const browserWindow = $(window);

interface PreloaderProps {
  active?: boolean;
}

const Preloader: React.FC<PreloaderProps> = function ({active: boolean = true}) {
  
  const [loading, setLoading] = useState(boolean);
  
  React.useEffect(() => {
    
    // :: 1.0 Preloader Active Code
    // browserWindow.on('load', function () {
    !loading &&
    $('.preloader').fadeOut('slow', function () {
      // $(this).remove();
      setLoading(false);
    });
    // });
    
  }, []);
  
  return (
    /* Preloader */
    <> {
      loading && <div className="preloader d-flex align-items-center justify-content-center">
            <div className="cssload-container">
                <div className="cssload-loading"><i></i><i></i><i></i><i></i></div>
            </div>
        </div>
      
    }
    </>
  );
}

export default Preloader;
