import React from 'react';
import imageFooterMap from '../../../Assets/Images/bg-img/footer-map.png'
import "./Footer.scss";

function FooterArea() {
  return (
    
    <footer className="footer-area">
      {/*##### Footer Area Start #####*/}
      <div className="container">
        <div className="row">
          
          {/*// Footer Widget Area*/}
          <div className="col-12 col-lg-5">
            
            <div className="footer-widget-area mt-50">
              {/*// <a href="#" className="d-block mb-5" style={{"color": "white", "fontSize": "16px"}}>*/}
              {/*// <img src="img/core-img/logo.png" alt=""/>*/}
              {/*// </a>*/}
              
              <h6 className="widget-title mb-5">
                
                <a href="http://inovuerj.pr2.uerj.br" className="d-block mb-5" style={{"color": "white", "fontSize": "16px"}}>
                  INOVUERJ
                </a>
                
              </h6>
              
              <section>
              <h2>INOVUERJ</h2>
              <p>Rua São Francisco Xavier, 524, Maracanã - Prédio<br/>Anexo, Térreo</p>
              </section>
              
              <section>
                <h2>Contato</h2>
                <p>+55 21 2334-0017<br/></p>
                <p>+55 21 2334-0018<br/>inovuerj@sr2.uerj.br</p>
              </section>
              
              <section>
              <h2>Redes Sociais</h2>
                {/*// Social Info*/}
                <div className="social-info wow fadeInUp" data-wow-delay="600ms">
                  {/*//            <a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a>*/}
                  <a href="https://www.facebook.com/inovuerj/?locale=pt_BR" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                  {/*<a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>*/}
                  {/*//            <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>*/}
                  {/*//            <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>*/}
                  {/*<a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>*/}
                  <a href="https://www.youtube.com/@inovuerj" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true" ></i></a>
                  <a href="https://www.instagram.com/inovuerj/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                </div>
              </section>
              
            </div>
          </div>
          
          {/*// Footer Widget Area*/}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="footer-widget-area mt-50">
              <h6 className="widget-title mb-5">Encontre-nos no mapa</h6>
              {/*<img src={imageFooterMap} alt=""/>*/}
  
              <div className="home-map-area">
                <iframe title="encontre-nos no maps" height="330px"
                  // srcDoc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.072861811214!2d-43.23998332390257!3d-22.91068323801189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997f5c648e1b43%3A0xcfc1e4f37c9d0fe4!2sInovUERJ!5e0!3m2!1sen!2sbr!4v1685408091006!5m2!1sen!2sbr"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.072861811214!2d-43.23998332390257!3d-22.91068323801189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997f5c648e1b43%3A0xcfc1e4f37c9d0fe4!2sInovUERJ!5e0!3m2!1sen!2sbr!4v1685408091006!5m2!1sen!2sbr"
                        allowFullScreen>
                </iframe>
              </div>
              
            </div>
  
           
            
          </div>
          
          {/*// Footer Widget Area*/}
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-widget-area mt-50">
              <h6 className="widget-title mb-5">Fale com a UERJ</h6>
              {/*<form action="src/Components/Sections/Footer#" method="post" className="subscribe-form">*/}
              {/*  <input type="email" name="subscribe-email" id="subscribeemail"*/}
              {/*         placeholder="Seu E-mail"/>*/}
              {/*  <button type="submit">Inscreva-se</button>*/}
              {/*</form>*/}
            </div>
            
            <div className="d-block mt-3 mb-3 pt-3 pb-3 bloco-ouvidoria" style={{"border": "0px"}}>
              <a href="https://www.ouvidoria.uerj.br/" target="_blank" className="d-block" style={{"color": "white", "fontSize": "16px"}}>Ouvidoria-Geral da UERJ</a>
              <div className='line'></div>
              <a href="https://www.ouvidoria.uerj.br/sic-servico-de-informacao-ao-cidadao/" target="_blank" className="d-block" style={{"color": "white", "fontSize": "16px"}}>Solicitação de acesso à informação</a>
            </div>
            
            
            
            
          </div>
          
          {/*// Copywrite Text*/}
          <div className="col-12">
            <div className="copywrite-text mt-30">
              
              <p>
                
                <a href="src/Components/Sections/Footer#"></a>
                
                {/*// Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0.*/}
                Copyright &copy;
                
                <script>document.write(new Date().getFullYear());</script>
                Alguns Direitos Reservados
                
                <span className="d-none">
                  | This template is made with
                  <i className="fa fa-heart-o" aria-hidden="true"></i> by
                  <a href="https://colorlib.com" target="_blank">Colorlib</a>
                </span>
                {/*// Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0.*/}
              
              </p>
            </div>
          </div>
        
        </div>
      
      </div>
      {/*##### Footer Area End #####*/}
    </footer>
  
  );
}

export default FooterArea;
