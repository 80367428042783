import React, { useEffect } from 'react';
import 'jquery-circle-progress';

const CircleProgress = ({$}) => {

    useEffect(() => {
        // Inicialize o plugin CircleProgress aqui ou adicione qualquer lógica necessária
        if ($.fn.circleProgress) {

            $('#circle').circleProgress({
                size: 194,
                emptyFill: "rgba(0, 0, 0, .0)",
                fill: '#92593d',
                thickness: '4',
                reverse: true
            });
            $('#circle2').circleProgress({
                size: 194,
                emptyFill: "rgba(0, 0, 0, .0)",
                fill: '#92593d',
                thickness: '4',
                reverse: true
            });
            $('#circle3').circleProgress({
                size: 194,
                emptyFill: "rgba(0, 0, 0, .0)",
                fill: '#92593d',
                thickness: '4',
                reverse: true
            });
            $('#circle4').circleProgress({
                size: 194,
                emptyFill: "rgba(0, 0, 0, .0)",
                fill: '#92593d',
                thickness: '4',
                reverse: true
            });
        }

    }, [$]);

    return (
      <></>
    );
};

export default CircleProgress;
