import imageGear from "../../Assets/Images/logo/gear.png";
import imageGradiente from "../../Assets/Images/logo/gradiente.png";
import imageVento from "../../Assets/Images/logo/vento.png";
import React from "react";
import {Link} from "react-router-dom";

export const Logo: React.FC<any> = () => {
  
  return (
    <>
      {/*Logo*/}
      {/*<a href="index.html" className="nav-brand"></a>*/}
      <div className="logo bloco-inovuerj" style={{"cursor": "pointer"}}>
        
        <Link to="#" className="nav-brand"/>
        <Link to="/" className="navbar-brand conjunto">
          
          <div className="bloco">
            
            <div className="logo-texto">
              
              <div className="logo-titulo">InovUerj<br/></div>
              
              <div className="logo-subtitulo">DEPARTAMENTO<br/>DE INOVAÇÃO<br/></div>
            
            </div>
            
            <section className="logo-animado">
              
              <img className="logo-2 img-fluid d-block " style={{"display": "inline-block"}}
                   src={imageGear}
                   alt="novo"/>
              
              <img className="grad img-fluid d-block"
                   src={imageGradiente}
                   alt="novo"/>
              
              <img className="vento img-fluid d-block"
                   src={imageVento}
                   alt="novo"/>
            
            </section>
          
          </div>
        
        </Link>
      
      </div>
    </>
  );
}