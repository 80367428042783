import React, {useEffect} from 'react';
import '../../Assets/LegacyPlugins/counterUp';


const CounterUp = ({$}) => {

    useEffect(() => {
        // :: 6.0 CounterUp Active Code
        // Inicialize o plugin counterUp aqui ou adicione qualquer lógica necessária
        if ($.fn.counterUp) {
            $('.counter').counterUp({
                delay: 10,
                time: 2000
            });
        }

    }, [$]);

    return (
        <></>
    );
};

export default CounterUp;
