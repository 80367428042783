import React, { useEffect } from 'react';
import 'jquery-sticky';

const AnchorPreventClick = ({$,browserWindow}) => {

    useEffect(() => {

        // :: 11.0 prevent default a click
        $('a[href="#"]').on('click', function ($) {
            $.preventDefault();
        });

    }, [$]);

    return (
      <></>
    );
};

export default AnchorPreventClick;
