import React, {useEffect} from 'react';
import "bootstrap/js/src/tooltip";

const ToolTip = ({$}) => {

    useEffect(() => {
        // Inicialize o plugin toolTip aqui ou adicione qualquer lógica necessária
        if ($.fn.tooltip) {
            $('[data-toggle="tooltip"]').tooltip()
        }




    }, [$]);

    return (
        <>
        </>
    );
};

export default ToolTip;
