import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import HomeScripts from '../../Home/HomeScripts';
import {Page} from '../../../../Interfaces/IPage';
import {WPNewsBySlug} from "../../../../Services/WordpressApi";
import imageIpeUerj from '../../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';

import './JustNewsComponent.scss';
import Preloader from "../../../Sections/Preloader/Preloader";
import {useParams} from "react-router-dom";
import Header from "../../../Sections/Header/Header";
import {IMenu} from "../../../../Interfaces/IMenu";
import FooterArea from "../../../Sections/Footer/FooterArea";
import {ativar} from "../../../Plugins/ClassyNav";


interface JustNewsComponentProps {
  menu: IMenu,
  slug?: string;
}

const JustNewsComponent: React.FC<JustNewsComponentProps> = ({menu}) => {
  
  const [page, setPage] = useState<Page | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const {slug} = useParams();
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        /* Pegar o slug do pagina aqui*/
        
        if (slug) {
          
          console.log(slug);

          const page: Page | null = await WPNewsBySlug(slug); // pega a page na api do wordpress
          if (page) {
            setPage(page);
          } else {
            setPage(null);
          }
          
        }
        
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, [slug]);
  
  if (loading || !menu) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (
    <>
      
      <Header menu={menu}/>
      
      <div>
        {/*##### Breadcumb Area Start #####*/}
        {
          page ?
            (
              <>
                {/*<SidebarMenu menuItems={menuItems} />*/}
                <section className="breadcumb-area d-flex align-items-center justify-content-center">
                  
                  <div className="breadcumb-area bg-img header-image-background"
                       style={{
                         "position": "absolute",
                         "backgroundImage": `url(${imageIpeUerj})`,
                         "zIndex": -1
                       }}>
                  </div>
                  
                  <div className="bradcumbContent">
                    <h2>Publicado {page.date_formatted}</h2>
                    {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
                  </div>
                
                </section>
                
                <section className="about-us-area" style={{"marginTop": "4rem"}}>
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-12">
                        <div className="about-text mb-100">
                          <div className="section-heading">
                            <div className="line-"></div>
                            <h2>{page.title.rendered}</h2>
                          </div>
                          {/*{items && <PageChildren items={items} />}*/}
                          <p dangerouslySetInnerHTML={{__html: page.content.rendered}}/>
                          {/*<a href="#" className="btn palatin-btn mt-50">Read More</a>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                
                <div style={{"marginBottom": "60px"}}></div>
              
              </>
            
            ) : (
              <div>Page not found</div>
            )
        }
        
        {/*##### Breadcumb Area End #####*/}
      
      </div>
      
      <FooterArea/>
      
      {/*<SCRIPTS>*/}
      <HomeScripts/>
      {/*</SCRIPTS>*/}
    
    </>
  );
};

export default JustNewsComponent;
