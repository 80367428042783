import {IMenuItem} from "./IMenuItem";

export const MENU_PRIMARY_ID: number = 22;
export const MENU_PRIMARY_SLUG: string = 'menu-principal';

export const MENU_SECUNDARY_ID: number = 23;
export const MENU_SECUNDARY_SLUG: string = 'menu-secundario';

export interface IMenu {
  ID: number;
  name: string;
  slug: string;
  description: string;
  count: number;
  items: IMenuItem[];
  meta: {
    links: {
      collection: string;
      self: string;
    };
  };
}