import React, { useEffect } from 'react';

// import $ from 'jquery';
const jQuery = require('jquery');
const $ = jQuery;


window.$ = $;
window.jQuery = $;


const NiceSelect = () => {

    useEffect(() => {

        import('jquery-nice-select').then(function(){
            // console.log(jQuery,'jquery-nice-select');
            jQuery('select').niceSelect();
        })


    }, []);

    return (
        <></>
    );
};

export default NiceSelect;
