import React from 'react';

function RoomsArea() {
  return (
    
    <section className="d-none rooms-area section-padding-100-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="section-heading text-center">
              <div className="line-"></div>
              <h2>Choose a room</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada lorem maximus mauris
                sceleri sque, at rutrum nulla dictum. Ut ac ligula sapien.</p>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center">
          
          {/*// Single Rooms Area*/}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
              {/*// Thumbnail*/}
              <div className="bg-thumbnail bg-img" style={{"backgroundImage": "url(img/bg-img/1.jpg)"}}></div>
              {/*// Price*/}
              <p className="price-from">From $150/night</p>
              {/*// Rooms Text*/}
              <div className="rooms-text">
                <div className="line"></div>
                <h4>Deluxe Room</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada lorem maximus mauris
                  sceleri sque.</p>
              </div>
              {/*// Book Room*/}
              <a href="#" className="book-room-btn btn palatin-btn">Book Room</a>
            </div>
          </div>
          
          {/*// Single Rooms Area*/}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-rooms-area wow fadeInUp" data-wow-delay="300ms">
              {/*// Thumbnail*/}
              <div className="bg-thumbnail bg-img" style={{"backgroundImage": "url(img/bg-img/8.jpg)"}}></div>
              {/*// Price*/}
              <p className="price-from">From $150/night</p>
              {/*// Rooms Text*/}
              <div className="rooms-text">
                <div className="line"></div>
                <h4>Double Suite</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada lorem maximus mauris
                  sceleri sque.</p>
              </div>
              {/*// Book Room*/}
              <a href="#" className="book-room-btn btn palatin-btn">Book Room</a>
            </div>
          </div>
          
          {/*// Single Rooms Area*/}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-rooms-area wow fadeInUp" data-wow-delay="500ms">
              {/*// Thumbnail*/}
              <div className="bg-thumbnail bg-img" style={{"backgroundImage": "url(img/bg-img/9.jpg)"}}></div>
              {/*// Price*/}
              <p className="price-from">From $100/night</p>
              {/*// Rooms Text*/}
              <div className="rooms-text">
                <div className="line"></div>
                <h4>Single Room</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec malesuada lorem maximus mauris
                  sceleri sque.</p>
              </div>
              {/*// Book Room*/}
              <a href="#" className="book-room-btn btn palatin-btn">Book Room</a>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
}

export default RoomsArea;
