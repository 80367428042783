import React, {useEffect} from 'react';
import {IMenuItem} from "../../../Interfaces/IMenuItem";
import SecundaryNav from "../../Sections/SecundaryNav";


interface BookNowNavProps {
  items: IMenuItem[]
}

const BookNowArea: React.FC<BookNowNavProps> = ({items}) => {
  
  useEffect(() => {
  }, []);
  
  return (
    <div className="book-now-area">
      {/*##### Book Now Area Start ##### */}
      <div className="container">
        <div className="row justify-content-center">
          
          <div className="col-12 col-lg-10">
            <div className="book-now-form">
              <form id="form-icones">
                
                <SecundaryNav items={items}/>
              
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*##### Book Now Area End ##### */}
    </div>
  );
}

export default BookNowArea;
