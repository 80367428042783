import React from "react";
import {Link} from "react-router-dom";
import logoPr2 from "../../Assets/Images/logo-pr2-v2.png";

export const LogoPr2: React.FC<any> = () => {
  
  return (
    <>
      {/*Logo*/}
      {/*<a href="index.html" className="nav-brand"></a>*/}
      <div style={{"cursor": "pointer"}} className="bloco-pr2">
        
        <Link to="#" className="nav-brand"/>
        <Link to="/" className="navbar-brand conjunto">
          
          <div className="logo-pr2">
              <img className="img-fluid d-block " src={logoPr2}
                   alt="novo"/>
          </div>
        
        </Link>
      
      </div>
    </>
  );
}