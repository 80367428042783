import React, {useEffect} from 'react';
import Wow from "wow.js";

// :: 12.0 wow Active Code: Efeito de loading ao descer a página (largura acima de 767)
// https://github.com/graingert/wow
const PluginWowJs = ({browserWindow}) => {

    useEffect(() => {
        new Wow().init();
    }, []);

    return (
        <></>
    );
};

export default PluginWowJs;
