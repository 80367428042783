import React, {useEffect, useState} from 'react';
import HeroArea from "../../Sections/HeroArea/HeroArea";
import BookNowArea from "./BookNowArea";
import PoolArea from "./PoolArea";
import RoomsArea from "./RoomsArea";
import HomeScripts from "./HomeScripts";
import Preloader from "../../Sections/Preloader/Preloader";
import {IMenu} from "../../../Interfaces/IMenu";
import MainNews from "../../Sections/MainNews/MainNews";
import Header from "../../Sections/Header/Header";
import FooterArea from "../../Sections/Footer/FooterArea";
interface BookNowNavProps {
  menu: IMenu,
  menuSecundary: IMenu
}

const Home: React.FC<BookNowNavProps> = ({menu, menuSecundary}) => {
  
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    
    setTimeout(() => {
      setLoading(false)
    }, 500)
    
    
  }, []);
  
  if (loading) {
    return <Preloader/>;
  }
  
  return (
    
    <>
      
      <Header menu={menu}/>
      
      {/*Carrossel e imagem de fundo*/}
      <HeroArea/>

      {/* Menu Secundário */}
      <BookNowArea items={menuSecundary.items}/>
      
      {/* Principais Noticias  */}
      <MainNews/>
      
      {/*<AboutUseArea/>*/}
      
      <PoolArea/>
      
      <RoomsArea/>
      
      {/*<ContactArea/>*/}
      
      <FooterArea/>
      
      {/*<SCRIPTS>*/}
      <HomeScripts/>
      {/*</SCRIPTS>*/}
    
    </>
  
  
  );
}

export default Home;
