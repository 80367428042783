import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import Header from '../../Sections/Header/Header';
import FooterArea from '../../Sections/Footer/FooterArea';
import HomeScripts from '../Home/HomeScripts';
import {Page} from '../../../Interfaces/IPage';
import {WPUerjNumbers} from "../../../Services/WordpressApi";

import './UerjNumbersPage.scss';
import {IMenu} from "../../../Interfaces/IMenu";
import Preloader from "../../Sections/Preloader/Preloader";
import imageIpeUerj from "../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg";
import photo800x1000 from "../../../Assets/Images/bg-img/800x1000-ph.jpg";
import {IUerjNumbers} from "../../../Interfaces/IUerjNumbers";


interface UerjNumbersPageProps {
  // slug: string;
  menu: IMenu,
}

const UerjNumbersPage: React.FC<UerjNumbersPageProps> = ({menu}) => {
  
  const [uerjNumbers, setUerjNumbers] = useState<IUerjNumbers[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        /* Pegar o slug do pagina aqui*/
        
        const uerjNumbersRes: IUerjNumbers[] | null = await WPUerjNumbers(); // pega a page na api do wordpress
        
        if (uerjNumbersRes) {
          setUerjNumbers(uerjNumbersRes);
        } else {
          setUerjNumbers(null);
        }
        
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, []);
  
  if (loading) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (<>
    
    <Header menu={menu}/>
    
    <div>
      {/*##### Breadcumb Area Start #####*/}
      {
        uerjNumbers ?
          (
            <>
              <section className="breadcumb-area d-flex align-items-center justify-content-center">
                
                <div className="breadcumb-area bg-img header-image-background"
                     style={{
                       "position": "absolute",
                       "backgroundImage": `url(${imageIpeUerj})`,
                       "zIndex": -1
                     }}>
                </div>
                
                <div className="bradcumbContent">
                  <h2>UERJ EM NÚMEROS</h2>
                  {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
                </div>
              
              </section>
              
              <section className="about-us-area" style={{"marginTop": "4rem"}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-12">
                      <div className="about-text">
                        <div className="section-heading">
                          <div className="line-"></div>
                          <h2>Indicadores</h2>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: '<p></p>'}}/>
                        {/*<a href="#" className="btn palatin-btn mt-50">Read More</a>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              
              <section className="rooms-area section-padding-0-100">
                <div className="container">
                  <div className="col-12" style={{paddingTop: "60px"}}>
                    
                    <div className="palatin-cool-facts-area">
                      
                      
                      
                      
                      <div className="row">
                        
                        {
                          uerjNumbers.map((item) => (
                            
                            <div className="col-12 col-sm-6 col-lg-3">
                              <div className="single-cool-fact mb-100">
                                <div className="scf-text">
                                  {/*<i className="icon-restaurant"></i>*/}
                                  <h2><span>{item.valor || 0}</span></h2>
                                  <p className='title'>{item.titulo}</p>
                                </div>
                              </div>
                            </div>
                          
                          ))
                        }
                        
                      </div>
                    
                    </div>
                  </div>
                </div>
              
              
              </section>
            
            </>
          
          ) : (
            <div>Page not found</div>
          )
      }
      
      {/*##### Breadcumb Area End #####*/}
    
    </div>
    
    
    <FooterArea/>
    
    {/*<SCRIPTS>*/}
    <HomeScripts/>
    {/*</SCRIPTS>*/}
  
  </>);
  
};

export default UerjNumbersPage;
