import React, {useEffect} from 'react';
import $ from "jquery";

const ClassyNav = ({$, options}: { $: JQueryStatic, options: {} }) => {
  useEffect(() => {
    // Cleanup on unmount
    return () => {
      ativar({}); // autoload
    };
  }, [$, options]);

  return <div className="classy-nav-container">{/* Render your navigation JSX here */}</div>;
};

export default ClassyNav;


export function ativar(options: any) {
  
  // Variables
  const navContainer = $('.classy-nav-container');
  const classy_nav = $('.classynav ul');
  const classy_navli = $('.classynav > ul > li');
  const navbarToggler = $('.classy-navbar-toggler');
  const closeIcon = $('.classycloseIcon');
  const navToggler = $('.navbarToggler');
  const classyMenu = $('.classy-menu');
  const var_window: any = $(window);

  // default options
  const defaultOpt = {
    theme: 'light',
    breakpoint: 991,
    openCloseSpeed: 350,
    megaopenCloseSpeed: 700,
    alwaysHidden: false,
    openMobileMenu: 'left',
    dropdownRtl: false,
    stickyNav: false,
    stickyFooterNav: false,
    ...options,
  };

  // light or dark theme
  if (defaultOpt.theme === 'light' || defaultOpt.theme === 'dark') {
    navContainer.addClass(defaultOpt.theme);
  }

  // open mobile menu direction 'left' or 'right' side
  if (defaultOpt.openMobileMenu === 'left' || defaultOpt.openMobileMenu === 'right') {
    navContainer.addClass(defaultOpt.openMobileMenu);
  }

  // dropdown rtl
  if (defaultOpt.dropdownRtl === true) {
    navContainer.addClass('dropdown-rtl');
  }
  // navbar toggler
  navbarToggler.on('click', () => {
    navToggler.toggleClass('active');
    classyMenu.toggleClass('menu-on');
  });

  // close icon
  closeIcon.on('click', () => {
    classyMenu.removeClass('menu-on');
    navToggler.removeClass('active');
  });

  // add dropdown & megamenu class in parent li class
  classy_navli.has('.dropdown').addClass('cn-dropdown-item');
  classy_navli.has('.megamenu').addClass('megamenu-item');

  // adds toggle button to li items that have children
  classy_nav.find('li a').each(function () {
    if ($(this).next().length > 0) {
      $(this).parent('li').addClass('has-down').append('<span class="dd-trigger"></span>');
    }
  });

  // expands the dropdown menu on each click
  classy_nav.find('li .dd-trigger').on('click', function (e) {
    e.preventDefault();
    $(this).parent('li').children('ul').stop(true, true).slideToggle(defaultOpt.openCloseSpeed);
    $(this).parent('li').toggleClass('active');
  });

  // add padding in dropdown & megamenu item
  $('.megamenu-item').removeClass('has-down');

  // expands the megamenu on each click
  classy_nav.find('li .dd-trigger').on('click', function (e) {
    e.preventDefault();
    $(this).parent('li').children('.megamenu').slideToggle(defaultOpt.megaopenCloseSpeed);
  });

  // check browser width in real-time
  function breakpointCheck() {
    const windoWidth = window.innerWidth;
    if (windoWidth <= defaultOpt.breakpoint) {
      navContainer.removeClass('breakpoint-off').addClass('breakpoint-on');
    } else {
      navContainer.removeClass('breakpoint-on').addClass('breakpoint-off');
    }
  }

  breakpointCheck();

  var_window.on('resize', () => {
    breakpointCheck();
  });

  // always hidden enable
  if (defaultOpt.alwaysHidden === true) {
    navContainer.addClass('breakpoint-on').removeClass('breakpoint-off');
  }

  // sticky
  if (defaultOpt.stickyNav === true) {
    var_window.on('scroll', () => {
      if (var_window.scrollTop() > 0) {
        navContainer.addClass('classy-sticky');
      } else {
        navContainer.removeClass('classy-sticky');
      }
    });
  }

  // footer sticky
  if (defaultOpt.stickyFooterNav === true) {
    navContainer.addClass('classy-sticky-footer');
  }


}