import React, {useEffect} from 'react';
import {Logo} from "../Logo";
  import {IMenu} from "../../../Interfaces/IMenu";
import HeaderNav from "../HeaderNav";
import {CONFIG} from "../../../Services/Config";
import "./Header.scss";
import {ativar} from "../../Plugins/ClassyNav";
import {LogoPr2} from "../LogoPr2";
import {LogoUerj} from "../LogoUerj";

interface HeaderProps {
  menu: IMenu
}

const redirectTo = () => {
  window.location.href = CONFIG.BASENAME;
};


const Header: React.FC<HeaderProps> = ({menu}) => {
  
  useEffect(() => {
    if(menu){
      ativar({});
    }
    return () => {};
  }, [menu]);
  
  return (
    
    <header className="header-area"> {/*Header Area Start*/}
      
      {/*Navbar Area*/}
      <div className="palatin-main-menu">
        
        <div className="classy-nav-container breakpoint-off">
          
          <div className="container-fluid">
            
            {/*Menu*/}
            <nav className="classy-navbar justify-content-between" id="palatinNav">
              
              <div className="col-2">
                <section className='logos d-flex justify-content-between align-items-center'>
                  
                  <LogoUerj/>
                  
                  <LogoPr2/>
                  {/*Logo*/}
                  <Logo className="logo-inovuerj"/>
                
                </section>
              </div>
              
              <div className="col-8">
                <section className='menus d-flex justify-content-center'>
                    
                    
                    {/*Menu*/}
                    <div className="classy-menu">
                      
                      {/* close btn */}
                      <div className="classycloseIcon">
                        <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                      </div>
                      
                      {
                        /*
                         * Carrega Páginas(pages) através do Menu do Wordpress.
                         * Propriedade "dontLoadPages" ignora carregamento automático de páginas da api
                         *  Ex: A página 'quem-somos' possui Component próprio "AboutUsPage".
                         */
                      }
                      <HeaderNav menuStart={menu}/>
                    
                    
                    </div>
                  
                  </section>
              </div>
              
              <div className="col ml-auto">
                
                {/*Navbar Toggler*/}
                <div className="classy-navbar-toggler">
                  <span className="navbarToggler"><span></span><span></span><span></span></span>
                </div>
                
                
                <section className='ouvidoria'>
                  
                  <div className="d-block mt-3 mb-3 pt-3 pb-3 bloco-ouvidoria" style={{"border": "0px"}}>
                    <a href="https://www.ouvidoria.uerj.br/" target="_blank" className="d-block"
                       style={{"color": "white", "fontSize": "12px"}}>Ouvidoria-Geral da UERJ</a>
                    <div className='line'></div>
                    <a href="https://www.ouvidoria.uerj.br/sic-servico-de-informacao-ao-cidadao/" target="_blank"
                       className="d-block" style={{"color": "white", "fontSize": "12px"}}>Solicitação de acesso à
                      informação</a>
                  
                  </div>
                
                </section>
              </div>
            
            </nav>
          
          </div>
        
        </div>
      
      </div>
    
    </header>
  );
}

export default Header;
