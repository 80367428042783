import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import HomeScripts from '../../Pages/Home/HomeScripts';
import {Page} from '../../../Interfaces/IPage';
import {fetchPageBySlug, filterMenuItemsByObjectType} from "../../../Services/WordpressApi";
import imageIpeUerj from '../../../Assets/Images/bg-img/uerj_ipe-rosa.jpg';

import './PageComponent.scss';
import Preloader from "../Preloader/Preloader";
import {IMenuItem} from "../../../Interfaces/IMenuItem";
import PageChildren from "../PageChildren";
import {IMenu} from "../../../Interfaces/IMenu";
import Header from "../Header/Header";
import FooterArea from "../Footer/FooterArea";
import {ativar} from "../../Plugins/ClassyNav";

interface PageComponentProps {
  slug: string;
  items?: IMenuItem[];
  parentPage?: IMenuItem;
  menu: IMenu;
}

const PageComponent: React.FC<PageComponentProps> = ({menu, slug, items, parentPage}) => {
  
  const [page, setPage] = useState<Page | null>(null);
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  
  
  useEffect(() => {
    
    const fetchPage = async () => {
      try {
        setLoading(true);
        
        const page: Page | null = await fetchPageBySlug(slug);
        if (page) {
          
          setPage(page);
          
          if (items) {
            const filteredMenuItems = filterMenuItemsByObjectType(items);
            setMenuItems(filteredMenuItems);
          }
          
          if (menu) {
            ativar({}); // autoload
            
            
          }
          
          
        } else {
          setPage(null);
        }
        
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPage();
    
  }, [slug]);
  
  
  useEffect(() => {
    if (parentPage) {
      console.log(parentPage);
    }
  }, [parentPage]);
  
  if (loading) return <Preloader/>;
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  return (
    <>
      
      <Header menu={menu}/>
      
      <div>
        {/*##### Breadcumb Area Start #####*/}
        {
          page ?
            (
              <>
                {/*<SidebarMenu menuItems={menuItems} />*/}
                <section className="breadcumb-area d-flex align-items-center justify-content-center">
                  
                  <div className="breadcumb-area bg-img header-image-background"
                       style={{
                         "position": "absolute",
                         "backgroundImage": `url(${imageIpeUerj})`,
                         "zIndex": -1
                       }}>
                  </div>
                  
                  <div className="bradcumbContent">
                    <h2>{parentPage?.title || page.title.rendered}</h2>
                    {/*<div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>*/}
                  </div>
                
                </section>
                
                <section className="about-us-area" style={{"marginTop": "4rem"}}>
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-12">
                        <div className="about-text mb-30">
                          <div className="section-heading">
                            <div className="line-"></div>
                            <h2>{page.title.rendered}</h2>
                          </div>
                          {/*{items && <PageChildren items={items} />}*/}
                          <p dangerouslySetInnerHTML={{__html: page.content.rendered}}/>
                          {/*<a href="#" className="btn palatin-btn mt-50">Read More</a>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                
                {items && <PageChildren items={items}/>}
                
                
                <div style={{"marginBottom": "60px"}}></div>
              
              </>
            
            ) : (
              <div>Page not found</div>
            )
        }
        
        {/*##### Breadcumb Area End #####*/}
      
      </div>
      
      <FooterArea/>
      
      {/*<SCRIPTS>*/}
      <HomeScripts/>
      {/*</SCRIPTS>*/}
    
    </>
  );
};

export default PageComponent;
