import React, {useState} from 'react';
import backgroundImageUerjLivro from '../../../Assets/Images/bg-img/bg-1-uerj-livro.jpeg';
import {Link} from "react-router-dom";
import "./HeroArea.scss";
import {IMenuItem} from "../../../Interfaces/IMenuItem";
import {WPAllNews} from "../../../Services/WordpressApi";
import {AxiosError} from "axios";
import Preloader from "../Preloader/Preloader";
import {Page} from "../../../Interfaces/IPage";
import OwlCarousel from "../../Plugins/OwlCarousel";
import $ from "jquery";

window.$ = window.jQuery = $;

interface HeroAreaProps {
  // items: IMenuItem[]
}

/**
 * Carrossel
 * @constructor
 */
const HeroArea: React.FC<HeroAreaProps> = ({}) => {

  const [slides, setSlides] = React.useState<Page[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);

  React.useEffect( () => {

    const fetchNews = async () => {

      try{

        setLoading(true);
        const news: Page[] | null = await WPAllNews();
        setSlides(news || null);

      } catch (e) {
        setError(error);
      } finally {
        setLoading(false);
      }

    }

    fetchNews();

  },[]);

  if (loading) return <Preloader/>;

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  // @ts-ignore
  return (
    
    <section className="hero-area">
      
      {/*<img src={uerj_livro}/>*/}

      
      <div className="hero-slides owl-carousel">
        
        {slides?.length === 0 && (
          <div className="single-hero-slide d-flex align-items-center justify-content-center" >
            
            <div className="slide-img bg-img" style={{"backgroundImage": `url(${backgroundImageUerjLivro})`}}></div>
            
            <div className="container">
              
              <div className="row justify-content-center">
                
                <div className="col-12 col-lg-9" style={{"textAlign": "center"}}>
                  
                </div>
              
              </div>
            
            </div>
          
          </div>
        )}
        
        {slides && slides.map((slide) => (
          
          <div className="single-hero-slide d-flex align-items-center justify-content-center" key={slide.id}>
            
            <div className="slide-img bg-img" style={{"backgroundImage": `url(${backgroundImageUerjLivro})`}}></div>
            
            <div className="container">
              
              <div className="row justify-content-center">
                
                <div className="col-12 col-lg-9" style={{"textAlign": "center"}}>
                  
                  {/*// Slide Content*/}
                  <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                    
                    <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                    
                    <h2 data-animation="fadeInUp" data-delay="500ms"
                        dangerouslySetInnerHTML={{__html: slide.title.rendered}}>
                      
                      {/*CURSOS <br/>*/}
                      {/*PROJETO INTEGRA <br/>*/}
                      {/*INÍCIO 05 DE SETEMBRO <br/>*/}
                    
                    </h2>
                    
                    <br/>
                    <br/>
                    {/*<p data-animation="fadeInUp" data-delay="700ms">LOCAL - INOVUERJ<br/>*/}
                    {/*</p>*/}
                    
                    <Link to={`/noticia/${slide.slug}`} className="btn palatin-btn mt-50"
                          data-animation="fadeInUp" data-delay="900ms">Saiba Mais</Link>
                  </div>
                
                </div>
              
              </div>

            </div>

          </div>

          )

        )}

      </div>

      {/* :: 4.0 Sliders Active Code *!/*/}
      <OwlCarousel />

    </section>
  
  );
}

export default HeroArea;
